<template>
  <div class="flex flex-row-reverse gap-x-3">
    <div v-if="step == 'MAP_ATTRIBUTES' && runInBackground">
      <Button
        id="next-button"
        type="button"
        class="text-white bg-[#2560D7] hover:bg-blue-700"
        buttonClass="btn-lg"
        @click="$emit('import')"
      >
        <template v-slot:label>Import</template>
      </Button>
    </div>

    <div v-if="step == 'REVIEW'">
      <Button
        id="next-button"
        type="button"
        class="text-white bg-[#2560D7] hover:bg-blue-700"
        buttonClass="btn-lg"
        @click="$emit('import')"
      >
        <template v-slot:label>Import</template>
      </Button>
    </div>

   <div v-else-if="showNext && !runInBackground">
     <Button
       id="next-button"
       type="button"
       class="text-white bg-[#2560D7] hover:bg-blue-700"
       buttonClass="btn-lg"
       @click="next()"
       :disabled="disableNext"
     >
       <template v-slot:label>Next</template>
     </Button>
   </div>
    <div v-if="showPrevious">
      <Button
        id="next-button"
        type="button"
        class="text-[#2560D7] border !border-[#2560D7] hover:text-white hover:bg-[#2560D7]"
        buttonClass="btn-lg"
        @click="previous()"
      >
        <template v-slot:label>Back</template>
      </Button>
    </div>
  </div>
</template>
<script>
import {csvExportSteps} from '@/common/constants'

export default ({
  name: 'bulk-import-progress',
  props: {
    step: String,
    disableNext: {
      type: Boolean,
      default: false
    },
    showPrevious: {
      type: Boolean,
      default: false
    },
    showNext: {
      type: Boolean,
      default: true
    },
    totalRecordsInCsv: {
      type: Number,
      default: 0
    },
    runInBackground: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      steps: csvExportSteps,
    }
  },
  components: {
    Button: () => import('@/ui/ui-kit/v2/Button.vue'),
  },
  methods: {
    next () {
      if (this.step === this.steps.MAP_ATTRIBUTES && this.totalRecordsInCsv > 100) {
        this.alertMessage(`Your records are more than 100 so it should be run background process`, 'error')
        return
      }
      this.$emit('next')
    },
    previous () {
      this.$emit('previous')
    }
  }
})
</script>
